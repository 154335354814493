import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";

import moment from "moment";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import {
  API_DATE_FORMAT,
  DATE_FORMAT,
  DateSorter,
  outboundRequestStatusColor,
} from "../../../shared/globals";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import TypeTranslator from "../../../shared/typetranslator";
import PickerModal from "../../../components/units_forms/outboundrequests/pickerlistmodal";
import {
  BackHandOutlined,
  CancelOutlined,
  DocumentScannerOutlined,
  HailOutlined,
  LocalShippingOutlined,
} from "@mui/icons-material";
import useUserService from "../../../shared/services/userservices";
import useCustomerAddressService from "../../../shared/services/customeraddressservice";
import useWarehouseService from "../../../shared/services/warehouseservice";
import useSKUService from "../../../shared/services/skuservice";
import OutboundReport from "../../../components/pages/reports/outboundrequest/outboundrequestreport";
import OnHoldModal from "../../../components/units_forms/outboundrequests/onholdmodal";
import ConfirmModal from "../../../components/alerts/confirmmodal";
import { useTenantContext } from "../../../context/tenantcontext";

const PackedList = (props: any) => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setError, setInfo } = useNotificationContext();
  const { getOutboundRequestsByStatus, moveToDispatched, moveToSelfCollection, cancelOutboundRequest } = useOutboundRequestService();
  const {
    outboundRequestStatuses,
    getOutboundRequestStatusValue,
    getOutboundDeliveryTypeValue,
    getRequestTypeValue,
  } = TypeTranslator();
  const [dataFromApi, setDataFromApi] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "outboundRequestId",
      headerName: t("Order Number"),
      flex:1
    },
    {
      field: "requestDate",
      headerName: `${t("Request Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "outboundDeliveryTypeId",
      headerName: `${t("Order Delivery Type")}`,
      flex: 1,
    },
    {
      field: "scheduleDate",
      headerName: `${t("Schedule Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "pocName",
      headerName: `${t("POC Name")}`,
      flex: 1,
    },
    {
      field: "pocPhone",
      headerName: `${t("POC Phone")}`,
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "requestTypeId",
      headerName: t("Request Type"),
      flex:1
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                outboundRequestStatusColor.light[
                outboundRequestStatuses.find(
                  (e: any) => e?.en_value === params.value
                )?.id
                ] || "white",
              color: `${outboundRequestStatusColor.regular[
                outboundRequestStatuses.find(
                  (e: any) => e?.en_value === params.value
                )?.id
              ]
                }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    columns.splice(1, 0, customerNameColumn);
  }

  const buildActions = (params: GridRowParams) => {
    let actionsArray = [];

    actionsArray.push(
      <GridActionsCellItem
        label={t("Move to Dispatch")}
        showInMenu
        onClick={() => {
          setOpenConfirmModal({
            open: true,
            message: t("you want to move this request to dispatch?"),
            fn: () => handleMoveToDispatch(params.row),
          });
        }}
        icon={<LocalShippingOutlined />}
      />
    );

    actionsArray.push(
      <GridActionsCellItem
        label={t("Move to Self Collection")}
        showInMenu
        onClick={() => {
          setOpenConfirmModal({
            open: true,
            message: t("you want to move this request to self collection?"),
            fn: () => handleMoveToSelfCollection(params.row),
          });
        }}
        icon={<HailOutlined />}
      />
    );


    actionsArray.push(
      <GridActionsCellItem
        label={t("Move to On Hold")}
        showInMenu
        onClick={() => {
          handleMoveToOnHold(params.row);
        }}
        icon={<BackHandOutlined />}
      />
    );


    actionsArray.push(
      <GridActionsCellItem
        label={t("Download Document")}
        showInMenu
        onClick={() => {
          setSelectedRequest(params.row);
          setShowPreview(true);
        }}
        icon={<DocumentScannerOutlined />}
      />
    );
    actionsArray.push(
      <GridActionsCellItem
        label={t("Cancel Request")}
        showInMenu
        onClick={() => {
          setOpenConfirmModal({
            open: true,
            message: t("you want to cancel this request?"),
            fn: () => handleCancelRequest(params.row),
          });
        }}
        icon={<CancelOutlined />}
      />
    );

    return actionsArray;
  };
  const handleCancelRequest = (data: any) => {
    setIsLoading(true);
    cancelOutboundRequest(data.outboundRequestId)
      .then(() => {
        setInfo(t("Request canceled successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleMoveToDispatch = (row: any) => {
    setIsLoading(true);
    moveToDispatched(row.outboundRequestId)
      .then((response: any) => {
        setInfo(t("Request moved to Dispatched successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleMoveToSelfCollection = (row: any) => {
    setIsLoading(true);
    moveToSelfCollection({ outboundRequestId: row.outboundRequestId, collectionDate: moment().format(API_DATE_FORMAT) })
      .then((response: any) => {
        setInfo(t("Request moved to Self Collection successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  const handleMoveToOnHold = (row: any) => {
    setSelectedRequest(row);
    setShowOnHoldModal(true);

  };

  const getData = () => {
    setIsLoading(true);
    const operation = userData?.userType === "S" ? getOutboundRequestsByStatus(tenantId, 8) : getOutboundRequestsByStatus(null, 8);
    operation
      .then((response: any) => {
        let updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.requestDate).format(DATE_FORMAT),
          scheduleDate: moment(request.scheduleDate).format(DATE_FORMAT),
          requestTypeId: getRequestTypeValue(
            request.requestTypeId
          ),
          outboundDeliveryTypeId: getOutboundDeliveryTypeValue(
            request.outboundDeliveryTypeId
          ),
          status: getOutboundRequestStatusValue(request.status),
        }));
        // sort desc by date
        updatedRows.sort((a: any, b: any) =>
          moment(a.requestDate).isBefore(moment(b.requestDate)) ? 1 : -1
        );
        setRows(updatedRows);
        setDataFromApi(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        requestDate,
        outboundDeliveryTypeId,
        scheduleDate,
        status,
        pocPhone,
        pocName,
      }: any) => {
        return (
          requestDate.toLowerCase().includes(value.toLowerCase()) ||
          getOutboundDeliveryTypeValue(outboundDeliveryTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          scheduleDate.toLowerCase().includes(value.toLowerCase()) ||
          // status.toLowerCase().includes(value.toLowerCase()) ||
          pocPhone.toString().toLowerCase().includes(value.toLowerCase()) ||
          pocName.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  useEffect(() => {
    getData();
  }, [tenantId]);

  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showOnHoldModal, setShowOnHoldModal] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState<any>({
    open: false,
    message: "",
    fn: null,
  });

  const handleConfirm = () => {
    openConfirmModal.fn();
    handleCloseConfirmModal();
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal({ open: false, message: "", fn: null });
  };
  return (
    <>
      {openConfirmModal.open && (
        <ConfirmModal
          open={openConfirmModal.open}
          onYesClick={handleConfirm}
          onNoClick={handleCloseConfirmModal}
          message={openConfirmModal.message}
        />
      )}
      {showOnHoldModal && (
        <OnHoldModal
          open={showOnHoldModal}
          handleClose={(refresh: boolean) => {
            setShowOnHoldModal(false)
            if (refresh) getData();
          }}
          outboundRequestId={selectedRequest.outboundRequestId}
        />
      )}

      {showPreview && (
        <OutboundReport
          open={showPreview}
          onClose={() => setShowPreview(false)}
          requestData={selectedRequest}
        />
      )}

      <ListPageDX
        listTitle={t("Picked")}
        name={t("Picked")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.outboundRequestId}
        isLoading={isLoading}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={true}
        filterByDate={filterByDate}
        // onEdit={onEdit}
        buildActions={buildActions}
        rowClick={false}
      />
    </>
  );
};

export default PackedList;

import { useEffect, useState } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Box, CssBaseline, Avatar, IconButton } from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";

import { AccountBox, Menu } from "@mui/icons-material";
import MenuItems from "./menuitems";
import { useTranslation } from "react-i18next";

import appLogo from "../../../assets/app_logo.png";
import tenantLogo from "../../../assets/tenant_logo.png";
import appLogoColor from "../../../assets/unitslogo_color.png";
import tenantLogoColor from "../../../assets/tenantlogo_color.png";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useTenantContext } from "../../../context/tenantcontext";
import useTenantService from "../../../shared/services/tenantservice";

const drawerWidth = 280; //306;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "relative",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(11)} + 1px) !important`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "relative",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props: any) {
  const { i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { tenantId, updateTenant } = useTenantContext();
  const { setError } = useNotificationContext();
  const { getTenants, getTenantById } = useTenantService();

  const [currentTenant, setCurrentTenant] = useState<any>(null);
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [boxMargin, setBoxMargin] = useState("280px");
  const autoCollapse = props.autoCollapse ?? false;

  useEffect(() => {
    setTimeout(() => {
      if (open) setBoxMargin("280px");
      else setBoxMargin("90px");
    }, 0);
  }, [open]);

  useEffect(() => {
    if (userData?.userType !== "S")
    {
      setIsLoading(true);
      getTenantById(userData?.tenantId)
        .then((res) => {
          setCurrentTenant(res);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [])

  return (
    <Box
      sx={{
        zIndex: "1200",
        mr: i18n.language == "en" ? boxMargin : 0,
        ml: i18n.language == "ar" ? boxMargin : 0,
      }}
    >
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: { border: 0, backgroundColor: "#fffff" },
        }}
        sx={{
          display: { xs: "none", md: "block" },
          position: "fixed",
          height: "100vh",
          overflowY: "auto",
        }}
        onMouseOver={() => (autoCollapse ? setOpen(true) : null)}
        onMouseOut={() => (autoCollapse ? setOpen(false) : null)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: 64,
            px: open ? 5 : 0,
          }}
        >
          {open && (
            <img
              src={userData?.userType === "S" ? appLogo : (currentTenant?.logoURL ? currentTenant?.logoURL : tenantLogo )}
              style={{
                height: "100%",
                width: "100%",
              }}
              alt="UNITS"
            />
          )}

          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ mx: 2, alignSelf: "center", height: "fit-content" }}
          >
            {open ? <Menu /> : <img src={userData?.userType === "S" ? appLogoColor : (currentTenant?.logoPath ? currentTenant?.logoPath : tenantLogoColor )} height={50} />}
          </IconButton>
        </Box>
        <MenuItems open={open} />
      </Drawer>
    </Box>
  );
}

const UserPicture = (props: any) => {
  if (props.profilePicture)
    return (
      <Avatar
        style={{
          height: "45px",
          width: "45px",
          display: "flex",
          margin: "5%",
          fontSize: "45px",
        }}
        src={props.profilePicture}
      />
    );
  else return <AccountBox />;
};

export const ProfilePicture = UserPicture;

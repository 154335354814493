import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SelectListDX from "./selectlistdx";
import { lighten, useTheme } from "@mui/material";

const LanguageSwitcher = (props: any) => {
  const { color } = props;
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    // i18n.language;
  }, []);

  const changeLanguageHandler = (e: any) => {
    const languageValue = e.target.value;
    setCurrentLanguage(languageValue);
    i18n.changeLanguage(languageValue);

    localStorage.setItem("lastLanguage", languageValue);
  };

  if (props.mode && props.mode === 2) {
    return (
      <LanguageSwitcherV2
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
    );
  }
  return (
    <SelectListDX
      fullWidth={false}
      variant="standard"
      inputProps={{
        sx: {
          color: color || "white",
        },
      }}
      sx={{
        width: "100% !important",
        "& .MuiInput-root:before": {
          borderBottomColor: color || "white !important",
        },
        "& .MuiSvgIcon-root": { color: color || "white !important" },
        textAlign: currentLanguage == "en" ? "left" : "right",
      }}
      value={currentLanguage}
      onChange={(e: any) => changeLanguageHandler(e)}
      items={[
        { value: "en", text: "English" },
        { value: "ar", text: "العربية" },
      ]}
    />
  );
};

export default LanguageSwitcher;

const LanguageSwitcherV2 = (props: any) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const { currentLanguage, setCurrentLanguage } = props;

  const handleLanguageChange = (lang: string) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("lastLanguage", lang);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        borderRadius: "50px",
        backgroundColor: currentLanguage === "en" ? lighten(theme.palette.primary.main, 0.7) : "#D1F5CE",
        cursor: "pointer",
        minHeight: "32px",
        padding: "3px",
        fontWeight:"700",
        fontSize:"14px"
      }}
    >
      <div
        onClick={() => handleLanguageChange("en")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "6px 15px",
          transition: "background-color 0.3s ease",
          backgroundColor: currentLanguage === "en" ? "transparent" : "#119143",
          color: currentLanguage === "en" ? theme.palette.primary.main : "#119143",
          borderRadius: "20px",
         
        }}
      >
        ENG
      </div>
      <div
        onClick={() => handleLanguageChange("ar")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "6px 15px",
          transition: "background-color 0.3s ease",
          backgroundColor: currentLanguage === "ar" ? "transparent" : theme.palette.primary.main,
          color: currentLanguage === "ar" ? "#119143" : theme.palette.primary.main,
          borderRadius: "20px",
        }}
      >
        عربي
      </div>
    </div>
  );
};

import { t } from "i18next";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import { Check, DocumentScannerOutlined, Preview, Start } from "@mui/icons-material";
import ListPageDX from "../../../components/business/listpagedx";
import moment from "moment";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import { DATE_FORMAT, inboundRequestStatusColor } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import TypeTranslator from "../../../shared/typetranslator";
import GRNReport from "../../../components/pages/reports/grn_report";
import { useTenantContext } from "../../../context/tenantcontext";

const GRNList = (props: any) => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setInfo, setError } = useNotificationContext();
  const { getAllGRNs, startCounting } = useInboundRequestService();
  const { getInboundRequestStatusValue, inboundRequestStatuses, } = TypeTranslator();

  const [GRNs, setGRNs] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedGRN, setSelectedGRN] = useState<any>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const GRNColumns: GridColDef[] = [
    {
      field: "grnNumber",
      headerName: `${t("GRN No.")}`,
      flex: 1,
    },
    {
      field: "inboundRequestId",
      headerName: `${t("Request No.")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "recievingDate",
      headerName: `${t("Receiving Date")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter(params) {
          return moment(params.value).format(DATE_FORMAT);
      },
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                inboundRequestStatusColor.light[
                  inboundRequestStatuses.find(
                    (e: any) => e?.en_value === params.value
                  )?.id
                ] || "white",
              color: `${
                inboundRequestStatusColor.regular[
                  inboundRequestStatuses.find(
                    (e: any) => e?.en_value === params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  const buildActions = (params: GridRowParams) => {
    let actionsArray = [];
    
    if (params.row.status === "Goods Received") {actionsArray.push(
      <GridActionsCellItem  
        label={t("Move to Counting")}
        showInMenu
        onClick={() => sendToCounting(params.row.inboundRequestId)}
        icon={<Start />}
      />
    )}

    actionsArray.push(
      <GridActionsCellItem
        label={t("Download Document")}
        showInMenu
        onClick={() => {
          setSelectedGRN(params.row);
          setShowPreview(true);
        }}
        icon={<DocumentScannerOutlined />}
      />
    );

    return actionsArray;
  };

  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    GRNColumns.splice(1, 0, customerNameColumn);
  }

  const onEdit = (data: any) => {
    navigate("/goodsrecievingdetails", {
      state: { requestData: data, },
    });
  };

  const sendToCounting = (id: number) => {
    setIsLoading(true);
    startCounting(id)
      .then((res) => {
        setInfo("Moved to counting successfully");
        navigate(`/countingpanel/${id}`);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getData = () => {
    setIsLoading(true);
    const operation = userData?.userType === "S" ? getAllGRNs(tenantId) : getAllGRNs();
    operation
      .then((response: any) => {
        let updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.recievingDate).format(DATE_FORMAT),
        }));
        // sort desc by date
        updatedRows.sort((a: any, b: any) =>
          moment(a.recievingDate).isBefore(moment(b.recievingDate)) ? 1 : -1
        );
        updatedRows = updatedRows.map((request: any) => ({
          ...request,
          status: getInboundRequestStatusValue(request.status),
        }));
        setRows(updatedRows);
        setGRNs(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(GRNs);
      return;
    }
    const newRows = GRNs.filter(
      ({
        recievingDate,
        grnNumber,
        status,
      }: any) => {
        return (
          grnNumber.toLowerCase().includes(value.toLowerCase()) ||
          recievingDate.toLowerCase().includes(value.toLowerCase()) ||
          status.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = GRNs.filter(({ createdDate }: any) => {
        return moment(createdDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(GRNs);
  };
  
  useEffect(() => {
    getData();
  }, [tenantId]);

  return (
    <>
      {showPreview && (
        <GRNReport
          open={showPreview}
          onClose={() => setShowPreview(false)}
          requestData={selectedGRN}
        />
      )}
      <ListPageDX
        listTitle={t("GRNs")}
        name={t("GRNs")}
        rows={rows}
        columns={GRNColumns}
        getRowId={(row: any) => row.inboundRequestId}
        isLoading={isLoading}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={true}
        filterByDate={filterByDate}
        buildActions={buildActions}
      />
    </>
  );
};

export default GRNList;
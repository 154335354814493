import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import { DataGrid, gridClasses, GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { usePDF } from "react-to-pdf";
import {
	downloadPDF,
	DATE_FORMAT,
	numberWithCommas,
	textToBase64Barcode,
} from "../../../shared/globals";
import TypeTranslator from "../../../shared/typetranslator";
import ButtonDX from "../../controls/buttondx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import BoxDX from "../../layout/boxdx";
import TypographyDX from "../../layout/typographydx";
import customerLogo from "../../../assets/customer_logo.png";
import tenantLogo from "../../../assets/tenant_logo.png";
import useWarehouseService from "../../../shared/services/warehouseservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import Loading from "../../layout/loading";
import { useAuthContext } from "../../../context/authcontext";
import useCustomerService from "../../../shared/services/customerservice";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import useCustomerAddressService from "../../../shared/services/customeraddressservice";
import DataGridDX from "../../layout/datagriddx";
import useTenantService from "../../../shared/services/tenantservice";

const GRNReport = (props: any) => {
	const { open, onClose, requestData } = props;
	const { getCustomerById } = useCustomerService();
	const { getCustomerAddressById } = useCustomerAddressService();
	const { getWarehouseById } = useWarehouseService();
	const { getInboundRequestById } = useInboundRequestService();
	const { getTenantById } = useTenantService();
	const { userData } = useAuthContext();
	const { setError } = useNotificationContext();
	const { toPDF, targetRef } = usePDF({
		filename: t(`GRN - ${requestData?.grnNumber}`),
		page: {
			margin: 10, // Set the margin in millimeters
			format: "a4", // You can choose 'a4' or other formats
			orientation: "portrait", // 'portrait' or 'landscape'
		},
	});
	const { getInboundRequestStatusValue } = TypeTranslator();

	const ItemsColumns: GridColDef[] = [
		{
			field: "skuName",
			headerName: `${t("Name")}`,
			flex: 1,
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
		},
		{
			field: "skuCode",
			headerName: `${t("Code")}`,
			minWidth: 200,
			align: "center",
			headerAlign: "center",
			renderCell(params) {
				return (
					<BoxDX
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<img
							width={250}
							style={{ objectFit: "cover" }}
							src={textToBase64Barcode(params.row.skuCode)}
							alt="skuCode"
						/>
						<TypographyDX fontSize={"16px"}>{params.row.skuCode}</TypographyDX>
					</BoxDX>
				);
			},
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
		},
		{
			field: "batchNumber",
			headerName: `${t("Batch Number")}`,
			minWidth: 150,
			align: "center",
			headerAlign: "center",
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
		},
		{
			field: "expiryDate",
			headerName: `${t("Expiry Date")}`,
			minWidth: 150,
			align: "center",
			headerAlign: "center",
			valueFormatter(params) {
				return params.value ? moment(params.value).format(DATE_FORMAT) : "";
			},
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
		},
		{
			field: "quantity",
			headerName: `${t("Number of Units")}`,
			minWidth: 150,
			align: "center",
			headerAlign: "center",
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
		},
		{
			field: "quantityReceived",
			headerName: `${t("Received Units")}`,
			minWidth: 150,
			align: "center",
			headerAlign: "center",
			disableColumnMenu: true,
			sortable: false,
			resizable: false,
		},
	];

	const [customerData, setCustomerData] = useState<any>(null);
	const [addressData, setAddressData] = useState<any>(null);
	const [warehouseData, setWarehouseData] = useState<any>(null);
	const [currentTenant, setCurrentTenant] = useState<any>(null);
	const [skuRows, setSkuRows] = useState<any>([]);
	const [grnData, setGrnData] = useState<any>(null);
	const [isPdfGenerating, setIsPdfGenerating] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handlePrintQuotation = async () => {
		downloadPDF({ toPDF, setIsPdfGenerating, onClose });
	};

	const getData = async () => {
		const p1 = getCustomerById(requestData.customerId);
		const p2 = getInboundRequestById(requestData.inboundRequestId);
		const p3 = getWarehouseById(requestData.warehouseId);
		const p4 = getCustomerAddressById(requestData.pickupAddressId);
		const p5 =
			userData?.userType !== "S" ? getTenantById(userData?.tenantId) : null;

		setIsLoading(true);
		Promise.all([p1, p2, p3, p4, p5])
			.then(([customer, request, warehouse, address, tenant]) => {
				setCustomerData(customer);
				setWarehouseData(warehouse);
				setGrnData(request);
				setAddressData(address);
				setSkuRows(request.inboundRequestSkus);
				setCurrentTenant(tenant);
			})
			.catch((err) => setError(err))
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (requestData) getData();
	}, []);

	return (
		requestData &&
		open && (
			<Dialog
				open={open}
				onClose={!isPdfGenerating && onClose}
				maxWidth={props.maxWidth ?? "xl"}
				disableEscapeKeyDown
			>
				<DialogTitle sx={{ textAlign: "center" }}>GRN Document</DialogTitle>
				<DialogActions
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
					}}
				>
					<ButtonDX
						disabled={isPdfGenerating}
						variant="outlined"
						onClick={onClose}
						sx={{ mb: { xs: 2, sm: "auto" } }}
					>
						{props.cancelLabel ?? t("Cancel")}
					</ButtonDX>
					<LoadingButtonDX
						color="primary"
						onClick={handlePrintQuotation}
						loading={isPdfGenerating || isLoading}
						sx={{ maxWidth: 120 }}
					>
						{t("Print")}
					</LoadingButtonDX>
				</DialogActions>
				<DialogContent>
					<div ref={targetRef} style={{ width: "1024px", padding: "10px" }}>
						{isLoading ? (
							<Loading />
						) : (
							<>
								<TypographyDX
									variant="h4"
									fontWeight="bold"
									// fontSize={"100px"}
									sx={{}}
								>
									GRN
								</TypographyDX>
								<BoxDX
									sx={{ display: "flex", justifyContent: "space-between" }}
								>
									<BoxDX
										sx={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<img
											width={250}
											style={{ objectFit: "cover" }}
											src={textToBase64Barcode(grnData?.grnNumber)}
											alt="grn_barcode"
										/>
										<TypographyDX fontSize={"16px"}>
											{`GRN # ${grnData?.grnNumber}`}
										</TypographyDX>
									</BoxDX>
									<BoxDX sx={{ display: "flex", justifyContent: "flex-end" }}>
										<img
											width={180}
											style={{ objectFit: "cover" }}
											src={
												userData?.userType === "S"
													? customerLogo
													: currentTenant?.logoPath
													? currentTenant?.logoPath
													: tenantLogo
											}
											alt="Customer Logo"
										/>
									</BoxDX>
								</BoxDX>
								<Divider sx={{ borderBottomWidth: 2 }} />
								<BoxDX sx={{ mt: 2, display: "flex" }}>
									<BoxDX>
										<TypographyDX fontSize={22} fontWeight={"bold"}>
											Sender Details
										</TypographyDX>
										<TypographyDX fontSize={18}>
											{customerData?.fullname}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{addressData?.streetAddress}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{addressData?.cityName}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{customerData?.email}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{customerData?.phoneNumber}
										</TypographyDX>
									</BoxDX>
									<Divider
										orientation="vertical"
										flexItem
										sx={{ mx: 5, borderWidth: 1 }}
									/>
									<BoxDX>
										<TypographyDX fontSize={22} fontWeight={"bold"}>
											Ship To
										</TypographyDX>
										<TypographyDX fontSize={18}>
											{warehouseData?.name}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{warehouseData?.pocName}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{warehouseData?.streetAddress}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{warehouseData?.cityName}
										</TypographyDX>
										<TypographyDX fontSize={16}>
											{warehouseData?.pocPhone}
										</TypographyDX>
									</BoxDX>
									<BoxDX
										sx={{
											flex: 1,
											display: "flex",
											alignItems: "end",
											justifyContent: "flex-end",
											flexDirection: "column",
										}}
									>
										<BoxDX sx={{ display: "flex", gap: 1 }}>
											<TypographyDX
												variant="p"
												fontWeight="bold"
												sx={{ justifyContent: "flex-end", width: 150 }}
											>
												Request No:
											</TypographyDX>
											<TypographyDX variant="p" sx={{ textAlign: "left" }}>
												{grnData?.inboundRequestId}
											</TypographyDX>
										</BoxDX>
										<BoxDX sx={{ display: "flex", gap: 1 }}>
											<TypographyDX
												variant="p"
												fontWeight="bold"
												sx={{ justifyContent: "flex-end", width: 150 }}
											>
												Total SKUs:
											</TypographyDX>
											<TypographyDX variant="p" sx={{ textAlign: "left" }}>
												{skuRows.length}
											</TypographyDX>
										</BoxDX>
										<BoxDX sx={{ display: "flex", gap: 1 }}>
											<TypographyDX
												variant="p"
												fontWeight="bold"
												sx={{ justifyContent: "flex-end", width: 150 }}
											>
												Total CBM:
											</TypographyDX>
											<TypographyDX variant="p" sx={{ textAlign: "left" }}>
												{grnData?.cargoSize}
											</TypographyDX>
										</BoxDX>
										<BoxDX sx={{ display: "flex", gap: 1 }}>
											<TypographyDX
												variant="p"
												fontWeight="bold"
												sx={{
													justifyContent: "flex-end",
													width: 150,
												}}
											>
												Status:
											</TypographyDX>
											<TypographyDX variant="p" sx={{ textAlign: "left" }}>
												{getInboundRequestStatusValue(grnData?.status)}
											</TypographyDX>
										</BoxDX>
										<BoxDX sx={{ display: "flex", gap: 1 }}>
											<TypographyDX
												variant="p"
												fontWeight="bold"
												sx={{ justifyContent: "flex-end", width: 150 }}
											>
												Request Date:
											</TypographyDX>
											<TypographyDX variant="p" sx={{ textAlign: "left" }}>
												{moment(grnData?.requestDate).format(DATE_FORMAT)}
											</TypographyDX>
										</BoxDX>
										<BoxDX sx={{ display: "flex", gap: 1 }}>
											<TypographyDX
												variant="p"
												fontWeight="bold"
												sx={{ justifyContent: "flex-end", width: 150 }}
											>
												Receiving Date:
											</TypographyDX>
											<TypographyDX variant="p" sx={{ textAlign: "left" }}>
												{moment(grnData?.recievingDate).format(DATE_FORMAT)}
											</TypographyDX>
										</BoxDX>
									</BoxDX>
								</BoxDX>
								<BoxDX mt={2} sx={{ display: "flex", flexDirection: "row" }}>
									<TypographyDX fontWeight={"bold"}>Instructions:</TypographyDX>
									<TypographyDX sx={{ mx: 1 }}>
										{grnData?.instructions}
									</TypographyDX>
								</BoxDX>
								<DataGridDX
									sx={{
										mt: 2,
										border: "none",
										outline: "none",
										boxShadow: "none",
										"& .MuiDataGrid-columnHeaders": {
											border: "none",
											outline: "none !important",
										},
										"& .MuiDataGrid-columnHeader": {
											border: "none",
											outline: "none !important",
										},
										"& .MuiDataGrid-cell": {
											border: "none",
											outline: "none !important",
										},
										"& .MuiDataGrid-row": {
											backgroundColor: "transparent !important",
										},
										[`& .${gridClasses.columnSeparator}`]: {
											[`&:not(.${gridClasses["columnSeparator--resizable"]})`]:
												{
													display: "none",
												},
										},
									}}
									onColumnHeaderClick={() => {}}
									hideFooter
									showCellVerticalBorder={false}
									disableColumnFilter={true}
									disableRowSelectionOnClick
									disableColumnSelector
									disableColumnMenu
									disableDensitySelector
									disableEval
									disableVirtualization
									rowsPerPage={100}
									getRowId={(row: any) => row.inboundRequestSkuId}
									rows={skuRows}
									columns={ItemsColumns}
									loading={isLoading}
									checkboxSelection={false}
								/>
							</>
						)}
					</div>
				</DialogContent>
			</Dialog>
		)
	);
};

export default GRNReport;

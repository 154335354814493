import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import appLogo from "../../../assets/app_logo.png";
import LanguageSwitcher from "../../controls/languageswitcherdx";
import {
  Logout
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ListItemIcon, useTheme } from "@mui/material";
import { useAuthContext } from "../../../context/authcontext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TypographyDX from "../../layout/typographydx";
import NotificationMenu from "../notificationmenu";
import SelectListDX from "../../controls/selectlistdx";
import useTenantService from "../../../shared/services/tenantservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useTenantContext } from "../../../context/tenantcontext";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function UnitsAppBar(props: any) {
  const { forQuote = false, getTenantId } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { tenantId, updateTenant } = useTenantContext();
  const { setError } = useNotificationContext();
  const { getTenants } = useTenantService();

  const [tenants, setTenants] = useState<any>([]);
  const [selectedTenant, setSelectedTenant] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
  //   null
  // );
  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
  //   null
  // );

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  React.useEffect(() => {
    if (userData?.userType === "S")
    {
      setIsLoading(true);
      getTenants()
        .then((res) => {
          setTenants(
            res.map((item: any) => {
              return { text: item.name, value: item.tenantId };
            })
          );
          if(!tenantId) updateTenant(res[0].tenantId);
          else {
            setSelectedTenant(tenantId);
          }
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    // <BoxDX sx={{ flexGrow: 1 }}>
    //   <AppBar
    //     position="fixed"
    //     sx={{
    //       backgroundColor: "#fff",
    //       boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
    //       zIndex: (theme) => theme.zIndex.drawer + 1,
    //     }}
    //   >
    <Container
      sx={{
        position: "fixed",
        width: "-webkit-fill-available",
        maxWidth: "-webkit-fill-available !important",
        ml: "2px",
        marginInlineEnd: "24px",
        backgroundColor: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar disableGutters>
        {forQuote && (
          <img
            src={appLogo}
            style={{
              width: "100%", // Adjusted to fit the container width
              maxWidth: "200px", // Set max width for large screens
              maxHeight: "80px", // Set max height for large screens
              objectFit: "contain",
            }}
            alt="UNITS"
          />
        )}
        {/* <img src={appLogo} height={75} alt="Units" /> */}
        {/* {!forQuote && (
          <IconButton size="large">
            <SearchOutlined />
          </IconButton>
        )} */}
        {userData.userType === "S" && (<Box sx={{ minWidth: 250 }}>
          <SelectListDX
            name = "tenant"
            label = {t("Tenant")}
            value={selectedTenant}
            items = {tenants}
            onChange={(e: any) => {
              setSelectedTenant(e.target.value);
              updateTenant(e.target.value);
            }}
            size="small"
            disabled={isLoading}
            InputLabelProps={{
              shrink: selectedTenant !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </Box>)}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, backgroundColor: 'grey' }}>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <LanguageSwitcher color={theme.palette.primary.main} mode={2} />
        </Box>
        {!forQuote && (
          <>
          <NotificationMenu />
          <AccountMenu />
          </>
        )}
       
      </Toolbar>
    </Container>
    //   </AppBar>
    // </BoxDX>
  );
}
export default UnitsAppBar;

const AccountMenu: React.FC = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut, userData } = useAuthContext();

  const [openProfile, setOpenProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    handleClose();
    signOut();
    navigate("/");
  };

  const onProfileClick = () => {
    if (userData?.userType === "T") {
      navigate(`/tenantdetails`, {
        state: {
          tenantId: userData?.tenantId,
        },
      });
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Account settings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={props.open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={props.open ? "true" : undefined}
            sx={{
              borderRadius: "10%",
              gap: "5px",
              // padding: "5px",
              // backgroundColor: "#F5F6F8",
              // "&:hover": {
              //   backgroundColor: "#ECEEF1",
              // },
            }}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
            <TypographyDX
              sx={{ fontSize: "11px", color: "#656E7A", fontWeight: 500 }}
            >
              {userData?.fullName || "User"}
            </TypographyDX>
          </IconButton>
        </Tooltip>

      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={onProfileClick}>
          <ListItemIcon>
            <Avatar sx={{ width: 28, height: 28 }} />
          </ListItemIcon>
          {t("Profile")}
        </MenuItem>
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon sx={{ paddingLeft: 0.5 }}>
            <Logout fontSize="small" sx={{ width: 27, height: 25 }} />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
      {/* {openProfile && (
        <ProfileModal
          open={openProfile}
          onClose={(reload: any) => {
            setOpenProfile(false);
            reload && window.location.reload();
          }}
        />
      )} */}
    </React.Fragment>
  );
};

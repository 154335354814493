import useSecureAPI from "../hooks/usesecureapi";

const useColorSchemeService = () => {
  const secureAPI = useSecureAPI();

  const getColorSchemeById = async (id: number) => {
    const response = await secureAPI.get(`ColorScheme/${id}`);
    return response.data;
  };

  const addColorScheme = async (data: any) => {
    const response = await secureAPI.post("ColorScheme", data);
    return response.data;
  };

  const updateColorScheme = async (data: any) => {
    const response = await secureAPI.put(`ColorScheme`, data);
    return response.data;
  };

  return {
    getColorSchemeById,
    addColorScheme,
    updateColorScheme,
  };
};

export default useColorSchemeService;

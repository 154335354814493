import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  DATE_FORMAT,
  inboundDeliveryTypes,
} from "../../../shared/globals";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import InboundRequestItems from "../../units_components/inboundrequest/inboundrequestitems";
import moment from "moment";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import BoxDX from "../../layout/boxdx";
import { useAuthContext } from "../../../context/authcontext";
import useWarehouseService from "../../../shared/services/warehouseservice";
import useCustomerAddressService from "../../../shared/services/customeraddressservice";
import ItemBox from "../../units_components/itembox";
import useSKUService from "../../../shared/services/skuservice";
import useUserService from "../../../shared/services/userservices";
import { InputAdornment } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TypeTranslator from "../../../shared/typetranslator";
import CheckBoxDX from "../../controls/checkboxdx";
import DynamicTabs from "../../layout/dynamictab";
import { useTenantContext } from "../../../context/tenantcontext";
import useCustomerService from "../../../shared/services/customerservice";

const InboundRequestForm = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const requestId = parseInt(id as string);
  const handleClose = () => {
    navigate("/inboundrequests");
  };

  const { getSKUsForCustomer } = useSKUService();
  const { setError } = useNotificationContext();
  // Refs for input fields
  const pocNameRef = useRef<HTMLInputElement>(null);
  const pocPhoneRef = useRef<HTMLInputElement>(null);

  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { setInfo } = useNotificationContext();
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();

  const {
    addInboundRequest,
    updateInboundRequest,
    getInboundRequestById,
    acceptInboundRequest,
    declineInboundRequest,
    approveModifications,
    rejectModifications,
  } = useInboundRequestService();
  const { getCustomers } = useCustomerService();
  const { getCustomerAddressForCustomer } = useCustomerAddressService();
  const { getWarehouses } = useWarehouseService();

  const defaultValues = {
    status: 1,
    customerId: userData.customerId,
    requestDate: moment(),
    inboundDeliveryTypeId: null,
    pickupAddressId: null,
    warehouseId: null,
    scheduleDate: moment(),
    pocName: "",
    pocPhone: "",
    inboundRequestSkus: [],
    receiptNotes: null,
    carTypeId: null,
    numberOfCars: null,
    cargoTypeId: null,
    cargoSize: null,
    instructions: "",
  };

  const [requestData, setRequestData] = useState<any>(defaultValues);
  const [requestDataFromApi, setRequestDataFromApi] =
    useState<any>(defaultValues);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const { cargoTypes,vehicleTypes } = TypeTranslator();

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setRequestData({
      ...requestData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setRequestData({
      ...requestData,
      [name]: moment(value).format(DATE_FORMAT),
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!requestData.pocName.trim().length && requestData.inboundDeliveryTypeId === 2)
      newErrors["pocName"] = "Poc Name is required";
    if (!requestData.pocPhone && requestData.inboundDeliveryTypeId === 2) newErrors["pocPhone"] = "Poc Phone is required";
    if (!requestData.inboundDeliveryTypeId)
      newErrors["inboundDeliveryTypeId"] = "Inbound Delivery Type is required";
    if (!requestData.customerId)
      newErrors["customerId"] = "Customer is required";
    if (!requestData.pickupAddressId && requestData.inboundDeliveryTypeId === 2)
      newErrors["pickupAddressId"] = "Pickup Address is required";
    if (!requestData.warehouseId)
      newErrors["warehouseId"] = "Warehouse is required";
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      if (newErrors["pocName"]) {
        pocNameRef.current?.focus();
      } else if (newErrors["pocPhone"]) {
        pocPhoneRef.current?.focus();
      }
      // Focus on more fields if needed

      return false; // Form is not valid
    }

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    if (validateForm()) {
      setIsLoading(true);

      let updatedData = {
        ...requestData,
        status: status == 2 ? 2 : 1,
      };
      // remove item_id from quotationItems to send to the server
      updatedData = {
        ...updatedData,
        inboundRequestSkus: requestData.inboundRequestSkus.map((item: any) => {
          const { item_id, inbound_request_id, ...rest } = item;
          return {
            ...rest,
            uomTypeId: 1,
          };
        }),
      };

      // pop those items out which dont have an skuId and but isDeleted flag
      updatedData = {
        ...updatedData,
        inboundRequestSkus: updatedData.inboundRequestSkus.filter(
          (item: any) => item.inboundRequestSkuId || !item.isDeleted
        ),
      };
      const operation = requestId ? updateInboundRequest : addInboundRequest;
      operation(updatedData)
        .then(() => {
          setInfo(
            requestId
              ? t("Request updated successfully")
              : t("Request added successfully")
          );
          handleClose();
        })
        .catch((error: any) => {
          setInfo(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (requestId) {
      setIsLoading(true);
      getInboundRequestById(requestId)
        .then((response: any) => {
          setIsFormEditable(() => {
            // form is only editable if status is 1 (Draft)
            if (response.status === 1) {
              return true;
            }
            return false;
          });

          // setting just to check if any sku data is modified or new sku is added to the list
          setRequestDataFromApi(response);

          setRequestData({
            ...response,
            inboundRequestSkus: response.inboundRequestSkus.map(
              (item: any, index: number) => ({
                ...item,
                item_id: index + 1 * 1000,
              })
            ),
          });
        })
        .catch((error: any) => {
          setInfo(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  const [addresses, setAddresses] = useState<any>([]);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [customersList, setCustomersList] = useState<any>([]);
  const getDropDownData = () => {
    const p1 = userData?.customerId
      ? getCustomerAddressForCustomer(userData?.customerId)
      : null;
    const p2 = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    const p3 = userData?.userType !== "C" ? (userData?.userType === "S" ? getCustomers(tenantId) : getCustomers()) : null;
    Promise.all([p1, p2, p3]).then(([addresses, warehouses, users]) => {
      setAddresses(
        addresses?.map((address: any) => ({
          text: address.streetAddress,
          value: address.customerAddressId,
        }))
      );
      setWarehouses(
        warehouses.map((warehouse: any) => ({
          text: warehouse.name,
          value: warehouse.warehouseId,
        }))
      );
      setCustomersList(
        users
          ?.map((user: any) => ({
            text: user.fullname,
            value: user.customerId,
          }))
      );
    });
  };
  useEffect(() => {
    getDropDownData();
  }, [tenantId]);

  const [skus, setSKUs] = useState<any>([]);
  const [skuDropdownData, setSkuDropdownData] = useState<any>([]);

  const getSkuDropDownData = () => {
    if (userData.userType !== "C") {
      if (requestData?.customerId) {
        const p1 = getCustomerAddressForCustomer(requestData?.customerId);
        const p2 = getSKUsForCustomer(requestData?.customerId);

        Promise.all([p1, p2])
          .then(([addresses, skus]) => {
            setAddresses(
              addresses?.map((address: any) => ({
                text: address.streetAddress,
                value: address.customerAddressId,
              }))
            );
            setSKUs(skus);
            setSkuDropdownData(
              skus?.map((sku: any) => ({ text: sku.name, value: sku.skuId }))
            );
          })
          .catch((err) => setError(err));
      }
    } else {
      setIsLoading(true);
      getSKUsForCustomer(userData?.customerId)
        .then((data) => {
          setSKUs(data);
          setSkuDropdownData(
            data?.map((sku: any) => ({ text: sku.name, value: sku.skuId }))
          );
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getSkuDropDownData();
  }, [requestData?.customerId]);

  const handleAcceptInboundRequest = () => {
    setIsLoading(true);
    acceptInboundRequest(requestId)
      .then(() => {
        setInfo(t("Request accepted successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };
  const handleDeclineInboundRequest = () => {
    setIsLoading(true);
    declineInboundRequest(requestId)
      .then(() => {
        setInfo(t("Request rejected successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleApproveModifications = () => {
    setIsLoading(true);
    approveModifications(requestId)
      .then(() => {
        setInfo(t("Modifications approved successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };
  const handleRejectModifications = () => {
    setIsLoading(true);
    rejectModifications(requestId)
      .then(() => {
        setInfo(t("Modifications rejected successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };

  const [isProcessInitiated, setIsProcessInitiated] = useState(false);
  const [isSkuDataModified, setIsSkuDataModified] = useState(false);

  // check if any sku data is modified or new sku is added to the list
  useEffect(() => {
    // remove item_id from quotationItems to send to the server
    let updatedData = {
      ...requestData,
      inboundRequestSkus: requestData.inboundRequestSkus.map((item: any) => {
        const { item_id, inbound_request_id, ...rest } = item;
        return rest;
      }),
    };

    // pop those items out which dont have an skuId and but isDeleted flag
    updatedData = {
      ...updatedData,
      inboundRequestSkus: updatedData.inboundRequestSkus.filter(
        (item: any) => item.inboundRequestSkuId || !item.isDeleted
      ),
    };
    // check if any sku data is modified or new sku is added to the list
    //
    if (
      JSON.stringify(updatedData.inboundRequestSkus) !==
      JSON.stringify(requestDataFromApi.inboundRequestSkus)
    ) {
      let isModified = false;
      for (let i = 0; i < updatedData.inboundRequestSkus.length; i++) {
        if (
          updatedData.inboundRequestSkus.length !=
          requestDataFromApi.inboundRequestSkus.length
        ) {
          isModified = true;
          break;
        }
        // we have to check for another condition if quantity and received quantity are same then we should not consider it as modified
        if (
          updatedData.inboundRequestSkus[i].quantityReceived != null &&
          updatedData.inboundRequestSkus[i].quantityReceived !=
            requestDataFromApi.inboundRequestSkus[i].quantity
        ) {
          isModified = true;
          break;
        }
      }
      if (isModified) {
        setIsSkuDataModified(true);
      } else {
        setIsSkuDataModified(false);
      }
    } else {
      // if no sku data is modified or new sku is added to the list
      setIsSkuDataModified(false);
    }
  }, [requestData.inboundRequestSkus]);

  const initiateProcess = () => {
    setIsProcessInitiated(true);
    navigate("/goodsrecievingdetails", {
      state: {
        requestData: requestData,
      },
    });
  };

  const handleProcessInboundRequest = (status: any) => {
    setIsLoading(true);
    let updatedData = {
      inboundRequestId: requestData.inboundRequestId,
      status: status,
      receiptNotes: requestData.receiptNotes,
      inboundRequestSkus: requestData.inboundRequestSkus.map((item: any) => {
        const { item_id, inbound_request_id, ...rest } = item;
        return rest;
      }),
    };

    // pop those items out which dont have an skuId and but isDeleted flag
    updatedData = {
      ...updatedData,
      inboundRequestSkus: updatedData.inboundRequestSkus.filter(
        (item: any) => item.inboundRequestSkuId || !item.isDeleted
      ),
    };

    // processInboundRequest(updatedData)
    //   .then(() => {
    //     setInfo(t("Request processed successfully"));
    //     handleClose();
    //   })
    //   .catch((error: any) => {
    //     setInfo(error);
    //   })
    //   .finally(() => setIsLoading(false));
  };

  // requestData.inboundDeliveryTypeId != 2 set carTypeId and numberOfCars to null
  useEffect(() => {
    if (requestData.inboundDeliveryTypeId != 2) {
      setRequestData({
        ...requestData,
        carTypeId: null,
        numberOfCars: null,
      });
    }
  }, [requestData.inboundDeliveryTypeId]);
  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Inbound Request Details")}
        onSaveClick={onSave}
        onYesClick={handleClose}
        handleGeneratePDF={props.handleGeneratePDF}
        // showSaveAndSubmit={true}
        showSaveAndSubmit={
          (requestData.status == 1 || !requestData.status) && (requestData.inboundRequestSkus).length > 0
        }
        inboundRequestStatus={requestData.status}
        onAcceptClick={handleAcceptInboundRequest}
        onDeclineClick={handleDeclineInboundRequest}
        onApproveModifications={handleApproveModifications}
        onRejectModifications={handleRejectModifications}
        initiateProcess={initiateProcess}
        onProcessClick={handleProcessInboundRequest}
        isProcessInitiated={isProcessInitiated}
        isSkuDataModified={isSkuDataModified}
      >
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled
            label={t("Request Date")}
            name="requestDate"
            value={moment(requestData.requestDate)}
            onChange={(value: any) => handleDateChange(value, "requestDate")}
            error={errors["requestDate"]}
            minDate={moment()}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled={!isFormEditable}
            label={t("Schedule Date")}
            name="scheduleDate"
            value={moment(requestData.scheduleDate)}
            onChange={(value: any) => handleDateChange(value, "scheduleDate")}
            error={errors["scheduleDate"]}
            minDate={requestData.requestDate}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Inbound Delivery Type")}
            name="inboundDeliveryTypeId"
            value={requestData.inboundDeliveryTypeId}
            onChange={handleInputChange}
            error={errors["inboundDeliveryTypeId"]}
            items={inboundDeliveryTypes}
            InputLabelProps={{
              shrink: requestData.inboundDeliveryTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        {userData.userType !== "C" ? (
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Customer")}
              name="customerId"
              value={requestData.customerId}
              onChange={handleInputChange}
              error={errors["customerId"]}
              items={customersList}
              InputLabelProps={{
                shrink: requestData.customerId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        ) : (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled
              label={t("Customer")}
              value={userData.fullName}
            />
          </GridDX>
        )}

        {requestData.inboundDeliveryTypeId == 2 && (
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Pickup Address")}
              name="pickupAddressId"
              value={requestData.pickupAddressId}
              onChange={handleInputChange}
              error={errors["pickupAddressId"]}
              items={addresses}
              InputLabelProps={{
                shrink: requestData.pickupAddressId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        )}

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Warehouse")}
            name="warehouseId"
            value={requestData.warehouseId}
            onChange={handleInputChange}
            error={errors["warehouseId"]}
            items={warehouses}
            InputLabelProps={{
              shrink: requestData.warehouseId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        {requestData.inboundDeliveryTypeId == 2 && (
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Type Of Car")}
              name="carTypeId"
              value={requestData.carTypeId}
              onChange={handleInputChange}
              error={errors["carTypeId"]}
              items={vehicleTypes}
              InputLabelProps={{
                shrink: requestData.carTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        )}
        {requestData.inboundDeliveryTypeId == 2 && (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Number Of Cars")}
              name="numberOfCars"
              value={requestData.numberOfCars}
              onChange={handleInputChange}
              error={errors["numberOfCars"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
              InputProps={{
                inputProps: { min: 1 },
              }}
            />
          </GridDX>
        )}

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Type Of Cargo")}
            name="cargoTypeId"
            value={requestData.cargoTypeId}
            onChange={handleInputChange}
            error={errors["cargoTypeId"]}
            items={cargoTypes}
            InputLabelProps={{
              shrink: requestData.cargoTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Cargo Size")}
            name="cargoSize"
            value={requestData.cargoSize}
            onChange={handleInputChange}
            error={errors["cargoSize"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
            InputProps={{
              inputProps: { min: 1 },
              endAdornment: <InputAdornment position="end">CBM</InputAdornment>,
            }}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            ref={pocNameRef}
            label={t("POC Name")}
            name="pocName"
            value={requestData.pocName}
            onChange={handleInputChange}
            error={errors["pocName"]}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            ref={pocPhoneRef}
            label={t("POC Phone")}
            name="pocPhone"
            value={requestData.pocPhone}
            onChange={handleInputChange}
            error={errors["pocPhone"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>

        <GridDX item xs={12}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Instructions")}
            name="instructions"
            value={requestData.instructions}
            onChange={handleInputChange}
            multiline
            rows={4}
          />
        </GridDX>

        {/* InboundRequest - check box for cargo arranged in pallets */}
        <GridDX item xs={12}>
          <CheckBoxDX
            disabled={!isFormEditable}
            label={t("Cargo arranged in pallets")}
            name="cargoArrangedInPallets"
            checked={requestData.cargoArrangedInPallets}
            onChange={handleInputChange}
          />
        </GridDX>
        
        {((userData.userType === "S" &&
          requestData.status === 3 &&
          isProcessInitiated) ||
          requestData.status === 5) && (
          <GridDX item xs={12}>
            <TextFieldDX
              disabled={requestData.status == 5}
              label={t("Receipt Notes")}
              name="receiptNotes"
              value={requestData.receiptNotes}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </GridDX>
        )}
      </AddEditEmbeddedModal>

      {requestData.customerId && (
        <ItemBox>
          <InboundRequestItems
            requestData={requestData}
            setRequestData={setRequestData}
            getData={getData}
            setIsChanged={setIsChanged}
            skus={skus}
            skuDropdownData={skuDropdownData}
            getSkuDropDownData={getSkuDropDownData}
            isFormEditable={isFormEditable}
            isProcessInitiated={isProcessInitiated}
          />
        </ItemBox>
      )}
    </BoxDX>
  );
};

export default InboundRequestForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Typography component="div" style={{ padding: 8, height: "100%" }}>
          {children}
        </Typography>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

interface Props {
  tabLabels: string[];
  tabContents: React.ReactNode[];
  defaultTabIndex?: number;
  setShowDatePicker?: Function;
}

const DynamicTabs = ({
  tabLabels,
  tabContents,
  defaultTabIndex,
  setShowDatePicker,
}: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(defaultTabIndex ?? 0);

  useEffect(() => {
    setValue(defaultTabIndex ?? 0);
  }, [defaultTabIndex]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (setShowDatePicker) setShowDatePicker(newValue === 0)
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs

        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        sx={{
          "& .MuiTabs-scroller": {
            overflow: "auto !important",
          },
        }}
      >
        {tabLabels.map((label: string, index: number) => (
          <Tab label={t(label)} key={index} />
        ))}
      </Tabs>
      <Divider sx={{ mb: 1 }} />
      {tabContents.map((content: React.ReactNode, index: number) => (
        <TabPanel value={value} index={index} key={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default DynamicTabs;

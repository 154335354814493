import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import {
  Badge,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Menu,
  Typography,
} from "@mui/material";
import {
  NotificationsNone as NotificationIcon,
  Visibility,
  VisibilityOff,
  Delete,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useNotificationService from "../../shared/services/notificationservice";
import { useAuthContext } from "../../context/authcontext";
import { useNotificationContext } from "../../context/notificationcontext";
import Loading from "../layout/loading";
import CheckBoxDX from "../controls/checkboxdx";
import ButtonDX from "../controls/buttondx";
import { useNavigate } from "react-router-dom";

const NotificationMenu = (props: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();
  const {
    getNotificationsByUser,
    getUnreadNotificationsCount,
    markAsRead,
    markUnRead,
    markAllUnRead,
    markAsArchived,
  } = useNotificationService();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [showUnRead, setShowUnRead] = useState(false);

  const open = Boolean(anchorEl);

  let interval: NodeJS.Timer;

  useEffect(() => {
    getData();

    interval = setInterval(async () => {
      getData();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    filterNotifications(showUnRead);
  }, [notifications]);

  const getData = async () => {
    setIsLoading(true);
    const p1 = getNotificationsByUser(userData.userId);
    const p2 = getUnreadNotificationsCount(userData.userId);
    Promise.all([p1, p2])
      .then(([notifications, count]) => {
        setNotifications(notifications);
        // setItems(notifications);
        setNotificationCount(count);
        // setShowUnRead(false);
        filterNotifications(showUnRead);
      })
      .catch((err) =>{} )
      .finally(() => setIsLoading(false));
  };

  const toMarkRead = async (id: number) => {
    setIsLoading(true);
    markAsRead(id)
      .then(() => getData())
      .catch((err) => {})
      // .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toMarkUnRead = async (id: number) => {
    setIsLoading(true);
    markUnRead(id)
      .then(() => getData())
      .catch((err) => {})
      // .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toMarkAllUnRead = async () => {
    setIsLoading(true);
    markAllUnRead(userData.userId)
      .then(() => getData())
      .catch((err) => {})
      // .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toArchive = async (id: number) => {
    setIsLoading(true);
    markAsArchived(id)
      .then(() => getData())
      .catch((err) => {})
      // .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const filterNotifications = (flag: boolean) => {
    if (flag) {
      const newItems = notifications.filter(
        (notification: any) => !notification.isRead
      );
      setItems(newItems);
    } else setItems(notifications);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toPreview = (notificationID: number, requestID: number, requestType: number) => {
    toMarkRead(notificationID);
    switch (requestType) {
      case 1: case 2: case 3: case 4: case 8:
      {
        navigate(`/inbounddetails/${requestID}`);
        break;
      }
      case 5: {
        navigate(`/countingpanel/${requestID}`);
        break;
      }
      case 6: {
        navigate(`/qcpanel/${requestID}`);
        break;
      }
      case 7: {
        navigate(`/putawaypanel/${requestID}`);
        break;
      }
      case 24: {
        navigate(`/pickingpanel/${requestID}`);
        break;
      }
      case 25: {
        navigate(`/packingpanel/${requestID}`);
        break;
      }
      case 21: case 22: case 23: case 26: case 27: case 28: case 29: case 30: case 31: case 32: case 33: {
        navigate(`/outbounddetails/${requestID}`);
        break;
      }
      default: { 
        break;
      }
    }
    
  };

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={handleClick}
        aria-controls={props.open ? "notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={props.open ? "true" : undefined}
        sx={{ mx: 2 }}
      >
        <Badge badgeContent={notificationCount} color="primary">
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List
          component="nav"
          sx={{ width: 450, direction: i18n.dir() }}
          disablePadding
        >
          <ListSubheader sx={{ color: "black" }}>
            <ListItem disablePadding sx={{ justifyContent: "space-between" }}>
              <Typography fontSize={20} fontWeight={"bold"}>
                {t("Notifications")}
              </Typography>
              <CheckBoxDX
                label={t("Only show unread")}
                checked={showUnRead}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setShowUnRead(e.target.checked);
                  filterNotifications(e.target.checked);
                }}
              />
            </ListItem>
            <Divider />
            {isLoading && <LinearProgress />}
          </ListSubheader>
          <ListItem disablePadding sx={{ justifyContent: "flex-end" }}>
            <ButtonDX
              variant="text"
              size="small"
              sx={{ width: 130 }}
              onClick={toMarkAllUnRead}
            >
              {t("Mark all read")}
            </ButtonDX>
          </ListItem>
          {notifications.length === 0 ? (
            <Typography sx={{ mx: 5 }}>
              {t("You have no notifications")}
            </Typography>
          ) : (
            items.map((item: any) => (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      const object = JSON.parse(item.dataJson);
                      toPreview(
                        item.userNotificationId,
                        parseInt(object.DataId),
                        parseInt(object.Type),
                      );
                    }}
                  >
                    <ListItemText
                      primary={
                        i18n.language === "en" ? item.enTitle : item.arTitle
                      }
                      secondary={
                        i18n.language === "en" ? item.enBody : item.arBody
                      }
                      primaryTypographyProps={
                        !item.isRead
                          ? { textAlign: "start", fontWeight: "bold" }
                          : { textAlign: "start" }
                      }
                      secondaryTypographyProps={{ textAlign: "start" }}
                    />
                  </ListItemButton>
                  <IconButton
                    edge="end"
                    onClick={
                      !item.isRead
                        ? () => toMarkRead(item.userNotificationId)
                        : () => toMarkUnRead(item.userNotificationId)
                    }
                    color={!item.isRead ? "primary" : "default"}
                  >
                    {!item.isRead ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => toArchive(item.userNotificationId)}
                    color={!item.isRead ? "primary" : "default"}
                    sx={{ mx: 1 }}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
                <Divider />
              </>
            ))
          )}
        </List>
      </Menu>
    </>
  );
};

export default NotificationMenu;

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { t } from "i18next";
import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import useCityService from "../../../shared/services/cityservice";
import ItemBox from "../../units_components/itembox";
import { InputAdornment } from "@mui/material";
import CheckBoxDX from "../../controls/checkboxdx";
import useWarehouseService from "../../../shared/services/warehouseservice";
import TypeTranslator from "../../../shared/typetranslator";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";
import TypographyDX from "../../layout/typographydx";
import ButtonDX from "../../controls/buttondx";
import DataGridDX from "../../layout/datagriddx";
import { Edit, Archive } from "@mui/icons-material";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useAuthContext } from "../../../context/authcontext";
import moment from "moment";
import { API_DATE_FORMAT, DATE_FORMAT } from "../../../shared/globals";
import useSubscriptionPackageService from "../../../shared/services/subscriptionpackageservice";
import useQuotationService from "../../../shared/services/quotationservice";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import UploadBoxDX from "../../controls/uploadboxdx";
import DeleteModal from "../../alerts/deleteModal";
import UseIndustryTypeService from "../../../shared/services/industrytypeservice";
import useTenantService from "../../../shared/services/tenantservice";

const TenantDetails = (props: any) => {
  const { state } = useLocation();
  const tenantId = state?.tenantId;
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { addTenant, updateTenant, deleteTenantLogo, getTenantById } = useTenantService();
  const { userData } = useAuthContext();
  const { getCities } = useCityService();
  const { getIndustryTypes } = UseIndustryTypeService();
  const defaultValues = {
    name: "",
    website: "",
    email: "",
    urlPrefix: "",
    phoneNumber: "",
    pocName: "",
    vatNumber: "",
    gstNumber: "",
    streetAddress: "",
    cityId: null,
    postalCode: "",
    logo: null,
    logoURL: null,
  };

  const navigate = useNavigate();
  const { setInfo, setError } = useNotificationContext();

  const [data, setData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [cityDropdownData, setCityDropdownData] = useState<any>([]);

  const getDropDownData = async () => {
    setIsLoading(true);
    getCities()
      .then((res) => {
        setCityDropdownData(
          res.map((city: any) => ({
            text: city.cityName,
            value: city.cityId,
          }))
        );
      })
    .catch((err) => setError(err))
    .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    if (name == "name") {
      setData({
        ...data,
        [name]: value,
        urlPrefix: value?.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
      });
    } else {
      setData({
        ...data,
        [name]: type == "checkbox" ? checked : value,
      });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!data.phoneNumber) {
      newErrors["phoneNumber"] = t("Phone number is required");
    }
    //trim the email before checking if it is empty
    if (!data.email?.trim()) {
      newErrors["email"] = t("Email is required");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  
  const onSave = (status: any) => {
    if (validateForm()) {
      if (!isDataChanged) {
        handleClose();
        return;
      }
      setIsSaving(true);
      setIsFormEditable(false);
  
      const formData = new FormData();
      for (var key in data) {
        if (data[key] !== null && data[key] !== undefined) formData.append(key, data[key]);
      }
  
      const operation = tenantId
        ? updateTenant(formData)
        : addTenant(formData);
      operation
        .then(() => {
          tenantId
            ? setInfo(t("Tenant updated successfully"))
            : setInfo(t("Tenant added successfully"));
          handleClose();
        })
        .catch((error: any) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const getData = () => {
    setIsLoading(true);
    const p1 = tenantId ? getTenantById(tenantId) : Promise.resolve(null);
    const p2 = getIndustryTypes();
    Promise.all([p1, p2]).then(([tenant, industryTypes]) => {
      if (tenant) {
        setData({ ...defaultValues, ...tenant });
      }
    }
    ).catch((err) => setError(err))
      .finally(() => setIsLoading(false));


  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [data,]);

  
  const handleImageChange = async (newImage: any, index: number) => {
    setIsDataChanged(true);
    if (newImage !== undefined) {
      if (newImage === null && data.logoURL) {
        setDeleteImgModal(true)
      }

      setData(() => ({
        ...data,
        logo: newImage,
      })
      )

    }
  };
  
  const [deleteImgModal, setDeleteImgModal] = useState(false)
  
  const confirmDelete = () => {
    setIsLoading(true);
    deleteTenantLogo(data.tenantId)
      .then((res) => {
        setInfo(t("Picture deleted successfully"));
        setDeleteImgModal(false)
        setData({ ...data, logo: null, logoURL: null })
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));

  };

  return (
    <BoxDX sx={{ width: "100%" }}>
      {deleteImgModal && (
        <DeleteModal
          open={deleteImgModal}
          deleteLabel={t("Delete")}
          name={'logo'}
          onDeleteClick={confirmDelete}
          onCancelClick={() => setDeleteImgModal(false)}
        />
      )}
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>

          <GridDX item xs={12} >
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Tenant Name")}
              name="name"
              value={data.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={true}
              label={t("URL Prefix")}
              name="urlPrefix"
              value={data.urlPrefix}
              onChange={handleInputChange}
              error={errors["urlPrefix"]}
            />
          </GridDX>

          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Website")}
              name="website"
              value={data.website}
              onChange={handleInputChange}
              error={errors["website"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Contact Name")}
              name="pocName"
              value={data.pocName}
              onChange={handleInputChange}
              error={errors["pocName"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Contact Number")}
              name="phoneNumber"
              value={data.phoneNumber}
              onChange={handleInputChange}
              error={errors["phoneNumber"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Email")}
              name="email"
              value={data.email}
              onChange={handleInputChange}
              error={errors["email"]}
            />
          </GridDX>


          {/* vatNumber: "", */}
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("VAT Number")}
              name="vatNumber"
              value={data.vatNumber}
              onChange={handleInputChange}
              error={errors["vatNumber"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("GST Number")}
              name="gstNumber"
              value={data.gstNumber}
              onChange={handleInputChange}
              error={errors["gstNumber"]}
            />
          </GridDX>
          <GridDX item xs={12} sx={{ overflowX: "auto" }}>
            <div style={{ display: "flex", paddingBottom: "10px" }}>
              <UploadBoxDX
                sx={{ width: "270px", height: "250px" }}
                image={data.logo}
                imageUrl={data.logoURL} // Pass the image URL to the component
                setImage={(newImage: any) => handleImageChange(newImage, 0)}
              />
            </div>
          </GridDX>
          <GridDX item xs={12}>
            <TypographyDX variant="h6">{t("Address")}</TypographyDX>
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Street Address")}
              name="streetAddress"
              value={data.streetAddress}
              onChange={handleInputChange}
              error={errors["streetAddress"]}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("City")}
              name="cityId"
              value={data.cityId}
              onChange={handleInputChange}
              items={cityDropdownData}
              InputLabelProps={{
                shrink: data.cityId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={6}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Postal Code")}
              name="postalCode"
              value={data.postalCode}
              onChange={handleInputChange}
              error={errors["postalCode"]}
            />
          </GridDX>



        </GridDX>
      </ItemBox>
    </BoxDX>
  );
};

export default TenantDetails;

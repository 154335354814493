import { useEffect, useState } from "react";
import GridDX from "../../components/layout/griddx";
import PageTitle from "../../components/layout/pagetitle";
import { useTranslation } from "react-i18next";
import SelectListDX from "../../components/controls/selectlistdx";
import useColorSchemeService from "../../shared/services/colorschemeservice";
import { useAuthContext } from "../../context/authcontext";
import ColorPickerDX from "../../components/controls/colorpickerdx";
import { useNotificationContext } from "../../context/notificationcontext";
import LoadingButtonDX from "../../components/controls/loadingbuttondx";
import TypeTranslator from "../../shared/typetranslator";

const Customizations = (props: any) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { fontTypes } = TypeTranslator();
  const { getColorSchemeById, updateColorScheme } = useColorSchemeService();
  const defaultData = {
    colorSchemeId: 0,
    errorColor: "",
    fontColor: "",
    fontId: 0,
    primaryColor: "",
    secondaryColor: "",
    successColor: "",
    tenantId: 0
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState(defaultData)

  const handleChange = (name:string, newValue: string) => {
    setData({
      ...data,
      [name]: newValue,
    });
  }

  const onSave = async () => {
    setIsSaving(true);
    updateColorScheme(data)
      .then((res) => {
        setInfo(t("Scheme updated successfully"));
        window.location.reload();
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };
  
  const getData = async () => {
    setIsLoading(true);
    getColorSchemeById(userData?.tenantId)
      .then((res: any) => setData(res))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, [])

  return (
    <GridDX
      container
      columnSpacing={1}
      rowSpacing={2}
      sx={{ width: "100%" }}
    >
      <GridDX item xs={12} justifyContent="space-between" alignItems="center" sx={{ marginBottom: 5 }}>
        <PageTitle />
        <LoadingButtonDX loading={isSaving} onClick={onSave}>
          {t("Save")}
        </LoadingButtonDX>
      </GridDX>
      <GridDX
        container
        rowSpacing={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 13px 1px #00000008",
          ml: "10px",
          p: 2,
        }}
      >
        <GridDX
          container
          p={1} 
          columnSpacing={1}
          rowSpacing={2}
        >
          <GridDX item xs={12} md = {3}>
            <ColorPickerDX
              loading={isLoading}
              label={t("Primary Color")}
              value={data?.primaryColor}
              onChange={(newValue : string ) => handleChange("primaryColor", newValue)}
            />
          </GridDX>
          <GridDX item xs={12} md = {3}>
            <ColorPickerDX
              loading={isLoading}
              label={t("Secondary Color")}
              value={data?.secondaryColor}
              onChange={(newValue : string ) => handleChange("secondaryColor", newValue)}
            />
          </GridDX>
        </GridDX>
        
        <GridDX
          container
          p={1} 
          columnSpacing={1}
          rowSpacing={2}
        >
          <GridDX item xs={12} md = {3}>
            <ColorPickerDX
              loading={isLoading}
              label={t("Success Color")}
              value={data?.successColor}
              onChange={(newValue : string ) => handleChange("successColor", newValue)}
            />
          </GridDX>
          <GridDX item xs={12} md = {3}>
            <ColorPickerDX
              loading={isLoading}
              label={t("Error Color")}
              value={data?.errorColor}
              onChange={(newValue : string ) => handleChange("errorColor", newValue)}
            />
          </GridDX>
        </GridDX>

        <GridDX
          container
          p={1} 
          columnSpacing={1}
          rowSpacing={2}
        >
          <GridDX item xs={6} md = {3}>
            <ColorPickerDX
              loading={isLoading}
              label={t("Font Color")}
              value={data?.fontColor}
              onChange={(newValue : string ) => handleChange("fontColor", newValue)}
            />
          </GridDX>
          <GridDX item xs={6} md = {3}>
            <SelectListDX
              label={t("Select Font")}
              items={fontTypes}
              value={data?.fontId}
              onChange={(e: any) => handleChange("fontId", e.target.value)}
            />
          </GridDX>
        </GridDX>
      </GridDX>
    </GridDX>
  );
};

export default Customizations;
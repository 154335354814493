import { MuiColorInput } from "mui-color-input";
import Skeleton from "react-loading-skeleton";

const ColorPickerDX = (props: any) => {

  if (props.loading)
      return (
        <Skeleton
          containerClassName="skeleton-container"
          style={{ height: 56 }}
        />
      );
  else
    return (
      <MuiColorInput
        fullWidth
        format="hex"
        {...props}
      />
    );
};

export default ColorPickerDX;
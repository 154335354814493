import {
  WarehouseOutlined,
  Inventory2Outlined,
  GroupsOutlined,
  ContentPasteOutlined,
  LocalShippingOutlined,
  CalendarViewMonthOutlined,
  CategoryOutlined,
} from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import CountBoxDX from "../../../layout/countboxdx";
import GridBoxDX from "../../../layout/gridboxdx";
import GridDX from "../../../layout/griddx";
import useDashboardService from "../../../../shared/services/dashboardservice";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../../../../context/notificationcontext";
import moment from "moment";
import { DATE_FORMAT } from "../../../../shared/globals";
import TypeTranslator from "../../../../shared/typetranslator";
import i18n from "../../../multilingual/i18n";
import AnnualUtilizationChart from "../../../units_components/annualUtilizationBox";
import CustomBarChart from "../../../charts/CustomBarChart";
import { useTenantContext } from "../../../../context/tenantcontext";
import { useAuthContext } from "../../../../context/authcontext";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const GeneralOverview = (props: any) => {
  const theme = useTheme();
  const { outboundRequestStatuses, inboundRequestStatuses,getOutboundRequestStatusId } = TypeTranslator()
  const { startDate, endDate } = props;
  const { t } = useTranslation();
  const {
    getStorageSummary,
    getGoodsSummary,
    getCustomerCount,
    getOrderCount,
    getExpiringItemsCount,
    getExpiringContracts,
    getInboundSummaryByStatus,
    getOutboundSummaryByStatus,
  } = useDashboardService();
  const { userData } = useAuthContext();
  const { tenantId }= useTenantContext();
  const { setError } = useNotificationContext();
  const pData = [30, 21, 66, 45, 80, 90, 23, 10, 54, 95, 88, 71];

  const [orderCount, setOrderCount] = useState<any>({
    inbounds: 0,
    outbounds: 0,
    inboundsDelivered: 0,
    outboundsDelivered: 0,
  });
  const [customerCount, setCustomerCount] = useState<any>({
    totalCustomers: 0,
    activeCustomers: 0,
  });
  const [goodsSummary, setGoodsSummary] = useState<any>({
    totalGoods: 112,
    occupiedSpaceInCBM: 73,
  });
  const [storageSummary, setStorageSummary] = useState<any>({
    freeCapacity: 35,
    occupiedCapacity: 65,
    availablePallets: 8,
  });
  const [inboundSummary, setInboundSummary] = useState<any>({
    labels: [],
    data: [],
  });
  const [outboundSummary, setOutboundSummary] = useState<any>({
    labels: [],
    data: [],
  });

  const [expiringItemsCount, setExpiringItemsCount] = useState(0);
  const [expiringContracts, setExpiringContracts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: t("Name"),
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "subscriptionPackageName",
      headerName: t("Package Name"),
      flex: 1,
    },
    {
      field: "endDate",
      headerName: t("Expiration Date"),
      width: 300,
      valueFormatter(params) {
        return moment(params.value).format(DATE_FORMAT);
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [startDate, endDate, i18n.language, tenantId]);

  const getData = async () => {
    const p1 = userData.userType === "S" ? getOrderCount(tenantId, startDate, endDate) : getOrderCount(null, startDate, endDate);
    const p2 = userData.userType === "S" ? getCustomerCount(tenantId, startDate, endDate) : getCustomerCount(null, startDate, endDate);
    const p3 = userData.userType === "S" ? getGoodsSummary(tenantId) : getGoodsSummary();
    const p4 = userData.userType === "S" ? getStorageSummary(tenantId) : getStorageSummary();
    const p5 = userData.userType === "S" ? getExpiringItemsCount(tenantId) : getExpiringItemsCount();
    const p6 = userData.userType === "S" ? getExpiringContracts(tenantId) : getExpiringContracts();
    const p7 = userData.userType === "S" ? getInboundSummaryByStatus(tenantId, startDate, endDate) : getInboundSummaryByStatus(null, startDate, endDate);
    const p8 = userData.userType === "S" ? getOutboundSummaryByStatus(tenantId, startDate, endDate) : getOutboundSummaryByStatus(null, startDate, endDate);

    setIsLoading(true);
    Promise.all([p1, p2, p3, p4, p5, p6, p7, p8])
      .then(([orders, customers, goods, storage, items, contracts, inbound, outbound]) => {
        setOrderCount(orders);
        setCustomerCount(customers);
        setGoodsSummary(goods);
        setStorageSummary(storage);
        setExpiringItemsCount(items);
        setExpiringContracts(contracts);

        // sort by status
        inbound.sort((a: any, b: any) => a.status - b.status);
        setInboundSummary({
          labels: inbound.map((item: any) => i18n.language === 'en' ? inboundRequestStatuses[item.status].en_value : inboundRequestStatuses[item.status].ar_value),
          data: inbound.map((item: any) => item.count),
        })
        // sort by status
        outbound.sort((a: any, b: any) => a.status - b.status)

        setOutboundSummary({
          labels: outbound.map((item: any) => i18n.language === 'en' ? outboundRequestStatuses[item.status].en_value : outboundRequestStatuses[item.status].ar_value),
          data: outbound.map((item: any) => item.count),
        })
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Storage")}
          icon={<WarehouseOutlined color="primary" fontSize="large" />}
          count1={storageSummary.occupiedCapacity}
          label1={t("Total Occupied")}
          count2={storageSummary.freeCapacity}
          label2={t("Free Capacity")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Goods Movement")}
          icon={<Inventory2Outlined color="primary" fontSize="large" />}
          count1={goodsSummary.totalGoods}
          label1={t("Goods")}
          count2={goodsSummary.occupiedSpaceInCBM}
          label2={t("CBM")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Customers")}
          icon={<GroupsOutlined color="primary" fontSize="large" />}
          count1={customerCount.totalCustomers}
          label1={t("Total")}
          count2={customerCount.activeCustomers}
          label2={t("Active")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={3}>
        <CountBoxDX
          title={t("Orders")}
          icon={<ContentPasteOutlined color="primary" fontSize="large" />}
          count1={orderCount.inbounds}
          label1={t("Inbounds")}
          count2={orderCount.outbounds}
          label2={t("Outbounds")}
          loading={isLoading}
        />
      </GridDX>
      <GridDX item xs={9} mt={1}>
        <AnnualUtilizationChart/>
      </GridDX>
      <GridDX container xs={3} rowSpacing={2} mt={1} pl={1}>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Delivered")}
            icon={<LocalShippingOutlined color="primary" fontSize="large" />}
            count1={orderCount.inboundsDelivered}
            label1={t("Inbounds")}
            count2={orderCount.outboundsDelivered}
            label2={t("Outbounds")}
            loading={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Racks Available")}
            icon={
              <CalendarViewMonthOutlined color="primary" fontSize="large" />
            }
            count1={storageSummary.availablePallets}
            loading={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <CountBoxDX
            title={t("Items Expiring")}
            icon={<CategoryOutlined color="primary" fontSize="large" />}
            count1={expiringItemsCount}
            loading={isLoading}
          />
        </GridDX>
      </GridDX>

      <GridDX item xs={12} mt={1}>
        <CustomBarChart
          title={t("Inbound Requests")}
          pData={inboundSummary.data}
          xLabels={inboundSummary.labels}
          isLoading={false}
          height={350}
          filterRange={false}
          colors={[theme.palette.primary.main]}
          yLabel={t("Requests")}
        />
      </GridDX>
      <GridDX item xs={12} mt={1}>
        <CustomBarChart
          title={t("Orders")}
          pData={outboundSummary.data}
          xLabels={outboundSummary.labels}
          isLoading={false}
          height={350}
          filterRange={false}
          warehouseFilter={false}
          colors={[theme.palette.primary.main]}
          onClick={(label:string) => {
            const statusId = getOutboundRequestStatusId(label);
            if(statusId){
              navigate(`/orders?filter=${encodeURIComponent(statusId)}`)
             };
          }}
          yLabel={t("Requests")}
        />
      </GridDX>
      <GridDX item xs={12}>
        <GridBoxDX
          title={t("Contracts Expiring in 30 Days")}
          rows={expiringContracts}
          columns={columns}
          getRowId={(row: any) => row.subscriptionId}
          loading={isLoading}
        />
      </GridDX>
    </GridDX>
  );
};

export default GeneralOverview;

import { createTheme, ThemeProvider } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { useAuthContext } from "../../context/authcontext";
import { useEffect, useState } from "react";
import useColorSchemeService from "../../shared/services/colorschemeservice";
import { useNotificationContext } from "../../context/notificationcontext";
import TypeTranslator from "../../shared/typetranslator";

const TenantThemeProvider = ( {children} : {
  children: React.ReactNode;
}) => {
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();
  const { fontTypes } = TypeTranslator();
  const { getColorSchemeById } = useColorSchemeService();

  const defaultTheme = {
    palette: {
      primary: {
        main: "#6800F4",
      },
      secondary: {
        main: "#FF8A36",
      },
      success: {
        main: "#52C41A",
      },
      error: {
        main: "#F94839",
      },
    },
    typography: {
      fontFamily: `'${fontTypes[0]?.text}', sans-serif`,
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [tenantScheme, setTenantScheme] = useState<any>(null)
  const [theme, setTheme] = useState(createTheme(defaultTheme));
  
  useEffect(() => {
    if (userData?.userType !== "S") getColorScheme();
    else setTheme(createTheme(defaultTheme));
  }, [userData])

  useEffect(() => {
    if (tenantScheme) {
      const newTheme = createTenantTheme(tenantScheme);
      setTheme(createTheme(newTheme));
    }
  }, [tenantScheme]);

  const getColorScheme = async () => {
    setIsLoading(true);
    if (userData?.tenantId)
      getColorSchemeById(userData?.tenantId)
        .then((res) => {
          setTenantScheme(res);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
  };

  const createTenantTheme = (data: any) => {
    return createTheme(
      {
        palette: {
          primary: {
            main: data?.primaryColor ?? "#6800F4",
          },
          secondary: {
            main: data?.secondaryColor ?? "#FF8A36",
          },
          success: {
            main: data?.successColor ?? "#52C41A",
          },
          error: {
            main: data?.errorColor ?? "#F94839",
          },
        },
        typography: {
          fontFamily: `'${fontTypes[data?.fontId - 1]?.text}', sans-serif`,
          allVariants: { color: data?.fontColor ?? "black" }
        },
      },
      arSA
    );
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default TenantThemeProvider;
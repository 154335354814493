import { Typography } from "@mui/material";
// import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import GridDX from "../layout/griddx";
import Loading from "../layout/loading";
import { BarChart, BarItemIdentifier } from "@mui/x-charts";
import UnitsFilterRange from "../units_controls/units_filterrange";
import BoxDX from "../layout/boxdx";
import SelectListDX from "../controls/selectlistdx";


const CustomBarChart = (props: any) => {
  const {
    title,
    pData,
    uData,
    pLabel,
    uLabel,
    xLabels,
    height,
    isLoading,
    filterRange = true,
    warehouseFilter,
    yLabel,
    onClick
    
  } = props;
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();

  const [filterFromDate, setFilterFromDate] = useState<any>(null);
  const [filterToDate, setFilterToDate] = useState<any>(null);

  useEffect(() => {
    setFilterFromDate(new Date("20140101"));
    setFilterToDate(new Date());
    // setFilterFromDate(moment("20140101"));
    // setFilterToDate(moment());
  }, []);

  const handleChangeFromDate = (date: any) => {
    const filterDate = new Date(date);
    if (filterToDate != null && filterDate > filterToDate) {
      setError(t("From date must be less than to date"));
      setFilterFromDate(null);
    } else {
      setFilterFromDate(filterDate);
    }
  };

  const handleChangeToDate = (date: any) => {
    const filterDate = new Date(date);
    if (filterFromDate != null && filterDate < filterFromDate) {
      setError(t("To date must be greater than from date"));
      setFilterToDate(null);
    } else {
      setFilterToDate(filterDate);
    }
  };

  const handleChartClick = (event:any, data:any) => {
    if (onClick) {
      const clickedLabel = data.axisValue;
      onClick(clickedLabel)
    }
  };
  const valueFormatter = (value: any) => `${value}%`;
  let series = [];
  uData && series.push({ data: uData, label: uLabel, id: "uvId" });
  pData &&
    series.push({ data: pData, label: pLabel, id: "pvId", valueFormatter });

  return (
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <BoxDX
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
          {title}
        </Typography>
        {filterRange && (
          <BoxDX mt={1}>
            <UnitsFilterRange
              filterFromDate={filterFromDate}
              filterToDate={filterToDate}
              handleChangeFromDate={handleChangeFromDate}
              handleChangeToDate={handleChangeToDate}
            />
          </BoxDX>
        )}
        {warehouseFilter && (
          <BoxDX mt={1} width={250}>
            <SelectListDX
              label={t("Warehouse")}
              name="warehouseId"
              items={[
                { text: "Warehouse 1", value: 1 },
                { text: "Warehouse 2", value: 2 },
                { text: "Warehouse 3", value: 3 },
                { text: "Warehouse 4", value: 4 },
              ]}
            />
          </BoxDX>
        )}
      </BoxDX>
      <GridDX
        item
        xs={7}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      ></GridDX>
      <GridDX
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <div style={{ minHeight: "200px", position: "relative" }}>
            <Loading
              styles={{
                height: "100%",
                width: "100%",
                left: 0,
                position: "absolute",
              }}
            />
          </div>
        ) : (
          <BarChart
            onAxisClick={handleChartClick}
            height={height || 300}
            series={series}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
            yAxis={[{ max: 100, label: yLabel || t("capacity (%)") }]}
            sx={{ direction: "ltr" }}
            rightAxis={i18n.language == "en" ? null : [{ max: 100, label: yLabel || t("capacity (%)") }]}
            leftAxis={i18n.language == "en" ? [{ max: 100, label: yLabel || t("capacity (%)") }] : null}
            {...props}
          />
        )}
      </GridDX>
    </BoxDX>
  );
};

export default CustomBarChart;
import { Divider } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DATE_FORMAT } from "../../../shared/globals";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import GridSearchFilterTextbox from "../../business/gridsearchfiltertextbox";
import ButtonDX from "../../controls/buttondx";
import BoxDX from "../../layout/boxdx";
import DataGridDX from "../../layout/datagriddx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import ItemPutAwayForm from "./itemputawayform";

const PutAwayForm = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const requestId = parseInt(id as string);
  const { getInboundRequestById, updateInboundRequest, markAsCompleted } = useInboundRequestService();
  const { setInfo, setError } = useNotificationContext();

  const ItemsColumns: GridColDef[] = [
    {
      field: "skuName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "skuCode",
      headerName: `${t("Code")}`,
      minWidth: 150,
    },
    {
      field: "expiryDate",
      headerName: `${t("Expiry Date")}`,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      valueFormatter(params) {
          return (params.value ? moment(params.value).format(DATE_FORMAT) : "");
      },
    },
    { field: "usableUnits",
      headerName: `${t("Usable Units")}`,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter(params) {
          const putAwayUnits = params.row.putAwayUnits ?? 0;
          return (params.row.usableUnits - putAwayUnits);
      },
    },
    {
      headerName: t("Actions"),
      field: "actions",
      align: 'center',
      headerAlign: 'center',
      minWidth:135,
      renderCell(params) {
          return (
            <ButtonDX
              sx={{ minWidth:110, m:1 }}
              disabled={params.row.putAwayUnits === params.row.usableUnits}
              onClick={()=>{
                setSelectedSKU(params.row);
                setOpenForm(true);
              }}
            >
              {t("Put Away")}
            </ButtonDX>
          );
      },
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    }
  ];

  const [requestData, setRequestData] = useState<any>(null);
  const [selectedSKU, setSelectedSKU] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [skus, setSKUs] = useState<any>([]);
  const [checked, setChecked] = useState<any>(0);
  const [isChanged, setIsChanged] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    navigate("/inboundrequests");
  };

  const onSave = () => {
    if (checked === skus.length) {
      setIsLoading(true);
      markAsCompleted(requestData.inboundRequestId)
        .then((res) => {
          setInfo(t("Request closed successfully"));
          navigate("/inboundrequests");
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else setError("Put Away all SKUs to close the request!");
  };

  const onClose = (confirm:boolean = false) => {
    if(confirm) getData();
    
    setSelectedSKU(null);
    setOpenForm(false);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(skus);
      return;
    }
    const newRows = skus.filter(
      (row: any) =>
        row.skuName.toLowerCase().includes(value.toLowerCase()) ||
        row.skuCode.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows.filter((sku: any) => !sku.checked));
  };

  const getData = () => {
    setIsLoading(true);
    getInboundRequestById(requestId)
      .then((res: any) => {
        if (res.status >= 10) navigate(`/inbounddetails/${id}`);
        else {
          let check = 0;
          const skuList = res.inboundRequestSkus.map((item: any) => {
            const putAway =  item.putAwayUnits === item.usableUnits; 
            if ( putAway ) check += 1;
            return {
              ...item,
              expiryDate: item.expiryDate ? moment(item.expiryDate).format(DATE_FORMAT) : null,
              checked: putAway ? true : false
            }
          });
          setRequestData(res);
          setChecked(check);
          setSKUs(skuList);
          setRows(skuList.filter((sku: any) => !sku.checked));
        }
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Put Away Panel")}
        onMarkCompleted={onSave}
        showMarkCompleted
        onYesClick={handleClose}
        isPrintable={false}
      >
        {openForm && <ItemPutAwayForm data={selectedSKU} warehouseId={requestData?.warehouseId} customerId={requestData?.customerId} handleClose={onClose}/>}
        <GridDX
          container
          alignItems="center"
          columnSpacing={1}
          rowSpacing={2}
        >
          <GridDX item xs={6} justifyContent="space-between">
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t("GRN No.")}`}</TypographyDX>
              <TypographyDX>{requestData?.grnNumber}</TypographyDX>
            </BoxDX>
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t("Request No.")}`}</TypographyDX>
              <TypographyDX>{requestData?.inboundRequestId}</TypographyDX>
            </BoxDX>
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t("Total SKUs")}`}</TypographyDX>
              <TypographyDX>{skus.length}</TypographyDX>
            </BoxDX>
          </GridDX>
          <Divider sx={{ my: 1, width: "100%" }}/>
          <GridDX item xs={12}>
            <TypographyDX fontWeight={"bold"}>{`${t("Put Away InProgress")} :`}</TypographyDX>
            <TypographyDX mx={2}>{`${checked}/${skus.length} ${t("SKUs")}`}</TypographyDX>
          </GridDX>
          <GridDX item xs={4}>
            <GridSearchFilterTextbox
              placeholder={t("Search")}
              sx={{
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px 0px #0000000F",
                border: "1px solid #D0D5DD",
              }}
              setGridFilterCriteria={setGridFilterCriteria}
            />
          </GridDX>
          <GridDX item xs={12}>
            <DataGridDX
              getRowId={(row: any) => row.inboundRequestSkuId}
              rows={rows}
              columns={ItemsColumns}
              loading={isLoading}
              disableRowSelectionOnClick={true}
              checkboxSelection={false}
            />
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>
    </BoxDX>
  );
};

export default PutAwayForm;
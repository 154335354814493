import { useTranslation } from "react-i18next";
import GridDX from "../../../layout/griddx";
import UtilizationBox from "../../../units_components/utilizationbox";
import GaugeBoxDX from "../../../layout/gaugeboxdx";
import SelectListDX from "../../../controls/selectlistdx";
import BoxDX from "../../../layout/boxdx";
import { useEffect, useState } from "react";
import ProgressBarDX from "../../../layout/progressbardx";
import TypographyDX from "../../../layout/typographydx";
import useDashboardService from "../../../../shared/services/dashboardservice";
import useWarehouseService from "../../../../shared/services/warehouseservice";
import { useNotificationContext } from "../../../../context/notificationcontext";
import Loading from "../../../layout/loading";
import { useAuthContext } from "../../../../context/authcontext";
import { useTenantContext } from "../../../../context/tenantcontext";

const Utilization = () => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { tenantId }= useTenantContext();
  const { setError } = useNotificationContext();
  const { getWarehouses } = useWarehouseService();
  const { getOverallWarehousesCapacity, getWarehouseCapacity } = useDashboardService();

  const defaultData = {
    totalCapacity: 0,
    usedCapacity: 0,
    warehouseId: null,
    warehouseName: "",
    areas: []
  };

  const [warehouses, setWarehouses] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState(0);
  const [overallData, setOverallData] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState<any>(defaultData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const operation = userData.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    operation
      .then((res) => setWarehouses(
        res.map((item: any) => {
          return { text: item.name, value: item.warehouseId };
        })
      ))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, [tenantId]);

  useEffect(() => {
    setIsLoading(true);
    if (warehouseId > 0) {
      getWarehouseCapacity(warehouseId)
        .then((res) => {
          setWarehouseData(res);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else
    {
      const operation = userData.userType === "S" ? getOverallWarehousesCapacity(tenantId) : getOverallWarehousesCapacity();
      operation
        .then((res) => setOverallData(res))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [warehouseId, tenantId])

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      {isLoading ? <Loading sx={{ width: "100%", height: "100%" }} /> : 
        <>
        <GridDX item xs={12} justifyContent="end" alignItems="center">
          <BoxDX width={15} height={15} backgroundColor={"#52C41A"} mx={1} />
          <TypographyDX fontSize={13}> {` 80% ${t("or more")},`} </TypographyDX>
          <BoxDX width={15} height={15} backgroundColor={"#FF8A36"} mx={1} />
          <TypographyDX fontSize={13}>
            {` ${t("between")} 50 - 80%,`}
          </TypographyDX>
          <BoxDX width={15} height={15} backgroundColor={"#F94839"} mx={1} />
          <TypographyDX fontSize={13}> {` 50% ${t("or less")}`} </TypographyDX>
        </GridDX>
        <GridDX item xs={12} justifyContent="end">
          <BoxDX mt={1} width={350}>
            <SelectListDX
              label={t("Warehouse")}
              name="warehouseId"
              value={warehouseId}
              onChange={(e: any) => setWarehouseId(e.target.value)}
              items={[...warehouses, { text: t("All Warehouses"), value: 0 }]}
            />
          </BoxDX>
        </GridDX>
        <GridDX
          item
          xs={12}
          alignItems="start"
          justifyContent="space-around"
          flexDirection="column"
        >
          {warehouseId === 0 && (
            <>
              <BoxDX
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="end"
                width={"100%"}
              >
                <TypographyDX fontSize={24} fontWeight="bold">
                  {t("Overall Capacity:")}
                </TypographyDX>
                <TypographyDX fontSize={22} fontWeight="bold">
                  {`${overallData?.usedCapacity}/${overallData?.totalCapacity} CBM`}
                </TypographyDX>
              </BoxDX>
              <ProgressBarDX value={isNaN(overallData?.usedCapacity / overallData?.totalCapacity) ? 0 : (overallData?.usedCapacity / overallData?.totalCapacity) * 100} />
            </>
          )}
        </GridDX>
        {warehouseId === 0 ? (
          overallData?.warehouses.map((warehouse: any) => (
            <GridDX item xs={3} maxHeight={350}>
              <GaugeBoxDX title={warehouse.name} value={warehouse.usedCBM} valueMax={warehouse.totalSpaceInCbm} />
            </GridDX>
          ))
        ) : (
          <>
            <GridDX item xs={9} minHeight={350}>
              {warehouseData.areas.length > 0 && <UtilizationBox warehouseData={warehouseData}/>}
            </GridDX>
            <GridDX item xs={3} maxHeight={350}>
              <GaugeBoxDX title={t("Total Occupied")} value={warehouseData?.usedCapacity} valueMax={warehouseData?.totalCapacity} />
            </GridDX>
          </>
        )}
        </>
      }
    </GridDX>
  );
};

export default Utilization;

import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  Tooltip,
  Zoom,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import { 
  CheckCircle as CheckCircleIcon, 
  Circle as CircleIcon, 
  LensOutlined as LensOutlinedIcon 
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DATE_FORMAT } from "../../../shared/globals";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12, 
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    backgroundColor: theme.palette.divider, 
    borderRadius: 2,
  },
  [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main, 
    },
  },
}));
const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed } = props;
  const theme = useTheme();

  return active ? (
    <CircleIcon
      sx={{
        color: theme.palette.primary.main,
        fontSize: 24, 
        boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
        borderRadius: "50%",
      }}
    />
  ) : completed ? (
    <CheckCircleIcon 
    sx={{
       color: theme.palette.primary.main,
        fontSize: 24,
        boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
        borderRadius: "50%",


       }}
     />
  ) : (

    <LensOutlinedIcon sx={{ color: theme.palette.grey[400], fontSize: 24 }} />
    
  
  );
};

const StatusDetails = ({ history }: { history?: any }) => {
  if (!history) {
    return <Typography variant="body1">No Data Available</Typography>;
  }

  return (
    <div>
      <Typography variant="body1">
        Date: {history.statusDate ? moment(history.statusDate).format(DATE_FORMAT): "No Date Available"}
      </Typography>
      {history.notes && (
        <Typography variant="body1">
          Notes: {history.notes}
        </Typography>
      )}
      {history.createdByUserName && (
        <Typography variant="body1">
          Created By: {history.createdByUserName}
        </Typography>
      )}
    </div>
  );
};

const StatusProgressBar = ({ currentStatus, statuses, statusHistory }: any) => {
  const { t, i18n } = useTranslation();
  const getCurrentStep = () => {
    const currentIndex = statuses.findIndex((status: any) => status && status.id === currentStatus);
    return Math.max(0, currentIndex - 1);
  };

  const getStepLabel = (status: any) => {
    return i18n.language === 'en' ? status.en_value : status.ar_value;
  };

  const getHistoryForStatus = (statusId: any) => {
    return statusHistory && statusHistory.find((historyItem: any) => historyItem.status === statusId);
  };

  return (
    <Stepper 
      activeStep={getCurrentStep()} 
      alternativeLabel
      connector={<ColorlibConnector/>}    
      sx={{
        flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row',
        '& .MuiStepLabel-label': {
          mt: 1,
          fontSize: '0.875rem',
        },
      }} 
    >
      {statuses.map((status: any) => (
        <Tooltip
          key={status.id}
          arrow
          title={<StatusDetails history={getHistoryForStatus(status.id)} />}
          placement='top'
          slots={{
            transition: Zoom,
          }}
        >
          <Step>
            <StepLabel StepIconComponent={CustomStepIcon}>
              {getStepLabel(status)}
            </StepLabel>
          </Step>
        </Tooltip>
      ))}
    </Stepper>
  );
};

export default StatusProgressBar;

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";
import DynamicTabs from "../../components/layout/dynamictab";
import ItemBox from "../../components/units_components/itembox";
import CountList from "./tab_content/countlist";
import GRNList from "./tab_content/grnlist";
import PutAwayList from "./tab_content/putawaylist";
import QCList from "./tab_content/qclist";
import RequestList from "./tab_content/requestlist";
import CompletedList from "./tab_content/completedlist";

const InboundRequests = () => {
  const { userData } = useAuthContext();
  const { setAddRecordfn } = useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const toCreate = () => {
    navigate("/inbounddetails");
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
  }, [i18n.language]);

  return (
    <ItemBox>
      <DynamicTabs
        tabLabels={[t("Requests"), t("Goods Received"), t("Counting"), t("QC"), t("Put Away"), t("Completed")]}
        tabContents={[
        <RequestList/>,
        <GRNList/>,
        <CountList/>,
        <QCList/>,
        <PutAwayList/>,
        <CompletedList/>
        ]}
      />
    </ItemBox>
  );
};

export default InboundRequests;
